// SPDX-FileCopyrightText: 2024 Mass Labs
//
// SPDX-License-Identifier: GPL-3.0-or-later

"use client";

import React, { useState, type ReactNode } from "react";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "../wagmi";
import { UserContextProvider } from "../context/UserContext";
import { StoreContextProvider } from "../context/StoreContext";
import { AuthProvider } from "@/context/AuthContext";
import { MerchantProvider } from "@/context/MerchantContext";
export function Providers(props: {
  children: ReactNode;
}) {
  const [queryClient] = useState(() => new QueryClient());
  return <WagmiProvider config={config} data-sentry-element="WagmiProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <RainbowKitProvider data-sentry-element="RainbowKitProvider" data-sentry-source-file="providers.tsx">
          <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="providers.tsx">
            <UserContextProvider data-sentry-element="UserContextProvider" data-sentry-source-file="providers.tsx">
              <StoreContextProvider data-sentry-element="StoreContextProvider" data-sentry-source-file="providers.tsx">
                <MerchantProvider data-sentry-element="MerchantProvider" data-sentry-source-file="providers.tsx">{props.children}</MerchantProvider>
              </StoreContextProvider>
            </UserContextProvider>
          </AuthProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>;
}